<template>
    <Loader :logo="loaderLogo"></Loader>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Loader from "@/components/Loader.vue";
import { loaderLogo } from "@/core/helpers/config";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getAxios } from "@/router";
import { ElNotification } from "element-plus";

export default defineComponent({
    name: "middleware",
    components: {
      Loader
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        onMounted(async() => {
          store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
          
          const token = router.currentRoute.value.params.token+''
          const redirect = router.currentRoute.value.params.redirect+''

          const response = await getAxios("/middleware"+ redirect +"/" + token);
          if(response.error){
            ElNotification({
              title: "Erreur",
              message: response.error,
              type: "error"
            })
            router.push(response.redirect)
          }else{
            //rendre generic le localstorage + parametre etc.
            if (redirect == "signatures") { 
              const borSeq = response.results.borSeq;
              const borSort = response.results.borSort;
              console.log(borSort)
              localStorage.setItem("currentBord", borSeq)
              store.dispatch(Actions.SET_CURRENT_BORD, {
                bordSeq: borSeq,
                bordType : borSort == 'Conservation intégrale' || borSort == 'Conservation partielle' ? 'versement' : 'élimination'
              });
              router.push({name: redirect, params: {idBord: borSeq}})
            }

            if (redirect == "password") {
              router.push({name: redirect, params: {token: token}})
            }
          }

          
          // router.push(redirect);
        });
        return{
          loaderLogo
        }
    },
});
</script>
